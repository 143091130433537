import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <>
            <footer className="main-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="footer-cl-01">
                                <img src="images/footer-logo.png" alt="img" />
                                <p>BytePal is a virtual AI assistant that learns and understands you better each day you use it. Once you start using it, it'll know if you're a cat person or dog person. You can text or just talk to it. The voice-activated prompts recognize your voice and you can start your conversation with them anywhere, and anytime.</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-cl-02">
                                <h3>Company</h3>
                                <ul>
                                    <li><Link to="/privacy">Privacy Policy</Link></li>
                                    <li><Link to="/terms">Terms & Conditions</Link></li>
                                    <li><a target="_blank" href="mailto:support@bytepal.io">support@bytepal.io</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-cl-03">
                                <h3>Download</h3>
                                <ul>
                                    <li><a target="_blank" href="https://apps.apple.com/pk/app/bytepal-ai/id1535389637"><img src="images/footer-apple-store-btn.png" alt="img" /></a></li>
                                    {/* <li><a target="_blank" href="https://apps.apple.com/pk/app/bytepal-ai/id1535389637"><img src="images/footer-google-play-btn.png" alt="img" /></a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="footer-copyright">
                <div className="container text-center">
                    <p>Copyright © 2020 BytePal AI, LLC. All rights reserved.</p>
                </div>
            </div>
        </>
    )
}

export default Footer;