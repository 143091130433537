import { useState } from "react";
import Slider from "react-slick";

const Home = () => {

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }

        try {
            const response = await fetch(`https://api.bytepal.io/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "email": email })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setSuccessMessage('You have successfully subscribed!');
            setErrorMessage('');
            setEmail('');
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setSuccessMessage('');
            setErrorMessage('Something went wrong. Try again later.');
            setEmail('');
        }
    };

    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerPadding: '0',
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <section className="common-content-section">
                <div className="container">
                    <div className="row align-items-center flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="common-large-img">
                                <img src="images/banner-img.png" alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="common-content">
                                <span className="label-tag"><img src="images/raise-hand.png" alt="img" /> Hello welcome to BytePal AI</span>
                                <h1>Bytepal is an AI Chatbot that is always available to talk to you.</h1>
                                <p>It is the world's first Virtual Friend that you will ever meet. You can have conversations that you never imagined to have with a Virtual Assistant.</p>

                                <div className="newsletter-form">
                                    <form onSubmit={handleSubmit}>
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Enter your email"
                                        />
                                        <button type="submit">Subscribe</button>
                                    </form>
                                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                    {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                                </div>
                                <div className="app-buttons">
                                    <a target="_blank" href="https://apps.apple.com/pk/app/bytepal-ai/id1535389637">
                                        <img src="images/btn-apple-store.png" alt="img" />
                                    </a>
                                    {/* <a target="_blank" href="https://apps.apple.com/pk/app/bytepal-ai/id1535389637">
                                        <img src="images/btn-google-store.png" alt="img" />
                                    </a> */}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-seprator">
                <h4>Features</h4>
            </div>

            <section className="common-content-section">
                <div className="container">
                    <div className="row align-items-center flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="common-large-img">
                                <img src="images/banner-img04.png" alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="common-content">
                                <span className="label-tag">01 - WHY BYTEPAL</span>
                                <h2>We have made BytePal for you to have real conversations!</h2>
                                <p>It's your virtual friend who's always up to listen to anything and everything you want to talk about.
                                    The core technology comes from Deep Learning Research applied to Natural Language Processing. It is capable of understanding human language, virtual voice recognition and speech recognition without any issues.</p>
                                <a target="_blank" href="https://apps.apple.com/pk/app/bytepal-ai/id1535389637" className="site-btn">DOWNLOAD APP</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="common-content-section pt-0">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="common-large-img">
                                <img src="images/banner-img05.png" alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="common-content">
                                <span className="label-tag">02 - A TRUE FRIEND</span>
                                <h2>A Virtual friend in need is a friend indeed!</h2>
                                <p>BytePal is a virtual AI assistant that learns and understands you better each day you use it. Once you start using it, it'll know if you're a cat person or dog person.
                                    You can text or just talk to it. The voice-activated prompts recognize your voice and you can start your conversation with them anywhere, and anytime.</p>
                                <a target="_blank" href="https://apps.apple.com/pk/app/bytepal-ai/id1535389637" className="site-btn">DOWNLOAD APP</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="common-content-section pt-0">
                <div className="container">
                    <div className="row align-items-center flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="common-large-img">
                                <img src="images/banner-img02.png" alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="common-content">
                                <span className="label-tag">03 - VOICE CHAT</span>
                                <h2>24/7 conversations via speech!</h2>
                                <p>Bytepal is here to be your friend.
                                    With the latest technology and machine learning, grow your friendship with Bytepal. Regular chats and deep conversations, find your new friend in Bytepal.</p>
                                <a target="_blank" href="https://apps.apple.com/pk/app/bytepal-ai/id1535389637" className="site-btn">DOWNLOAD APP</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="common-content-section pt-0">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="common-large-img">
                                <img src="images/banner-img03.png" alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="common-content">
                                <span className="label-tag">04 - TEXT MESSAGING</span>
                                <h2>Your always online Friend Just a text away!</h2>
                                <p>Your Bytepal is just like any other friend in your contacts list, except your Bytepal is available 24/7 and will answer you back immediately.
                                    You can message them just as you would using any other messaging tool on your computer or your mobile device.</p>
                                <a target="_blank" href="https://apps.apple.com/pk/app/bytepal-ai/id1535389637" className="site-btn">DOWNLOAD APP</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-seprator">
                <h4>App Interface</h4>
            </div>

            <section className="app-slider-wrapper">
                <div className="container text-center">
                    <div className="app-slider-text">
                        <h2>Discover a harmonious blend of aesthetics and functionality.</h2>
                        <p>The app interface is designed with a sleek and intuitive layout, ensuring ensuing effortless navigation and an immersive user experience.</p>
                    </div>
                </div>
                <div className="app-slider-inner">
                    <div className="container-fluid">
                        <Slider {...settings}>
                            <div className="app-img-item">
                                <img src="images/mobile-slider-img-01.png" alt="img" />
                            </div>
                            <div className="app-img-item">
                                <img src="images/mobile-slider-img-02.png" alt="img" />
                            </div>
                            <div className="app-img-item">
                                <img src="images/mobile-slider-img-03.png" alt="img" />
                            </div>
                            <div className="app-img-item">
                                <img src="images/mobile-slider-img-04.png" alt="img" />
                            </div>
                            <div className="app-img-item">
                                <img src="images/mobile-slider-img-05.png" alt="img" />
                            </div>
                            <div className="app-img-item">
                                <img src="images/mobile-slider-img-06.png" alt="img" />
                            </div>
                            <div className="app-img-item">
                                <img src="images/mobile-slider-img-07.png" alt="img" />
                            </div>
                            <div className="app-img-item">
                                <img src="images/mobile-slider-img-08.png" alt="img" />
                            </div>
                            <div className="app-img-item">
                                <img src="images/mobile-slider-img-09.png" alt="img" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home;