import React, { useEffect, useState } from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

function App() {

  return (
    <>
      <Router>
        <Header />

        <ScrollToTop />

        <main className="main-inner-area">
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/privacy' element={<PrivacyPolicy />} />
            <Route exact path='/terms' element={<TermsConditions />} />
          </Routes>
        </main>

        <Footer />
      </Router>
    </>
  );
}

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

export default App;