import { useState } from "react";
import { TfiAlignRight, TfiClose } from "react-icons/tfi";
import { Link } from "react-router-dom";

const Header = () => {

    const [active, setActive] = useState(false);

    const menuHandle = () => {
        setActive(!active)
    }

    return(
        <header className="main-header">
            <div className="container">
                <div className="row">
                    <div className="site-logo">
                        <Link to="/"><img src="images/footer-logo.png" /></Link>
                    </div>
                    <div className={`site-navbar ${active ? 'active' : ''}`}>
                        <div className="mobile-menu-close d-none" onClick={menuHandle}>
                            <TfiClose />
                        </div>
                        <ul>
                            <li><Link to="/" onClick={menuHandle}>Home</Link></li>
                            <li><a href="#" onClick={menuHandle}>Features</a></li>
                            <li><a href="#" onClick={menuHandle}>About us</a></li>
                            <li><a href="#" onClick={menuHandle}>Contact</a></li>
                        </ul>
                    </div>
                    <div className="mobile-menu-button d-none" onClick={menuHandle}>
                        <TfiAlignRight />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;